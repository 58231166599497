.details {
    font-size: small;
    padding: 35px 15px;
}

.details__block, .benefit_block {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px 10px;
}

.premium_block {
    display: grid;
    grid-template-columns: 50% 25%;
    grid-gap: 10px 10px;
}

.set_premium__block > form > .premium_block_input, .benefit_block .input > .benefit_block_input {
    display: flex;
    align-items: center;
    background: var(--chakra-colors-gray-200);
    padding: 8px 15px;
    border-radius: 6px;
    border: none;
    outline-width: 0;
    width: 180px;
    margin-right: 5px;
    font-size: 13px;
}
.set_premium__block > form > .premium_block_input > input, .benefit_block .input > .benefit_block_input > input  {
    background: transparent;
    outline: none;
    width: 135px;
}

.error_border {
    border-color: #E53E3E;
    box-shadow: 0 0 0 1px #e53e3e;
}
.set_premium__block .error {
    color: var(--chakra-colors-red-500);
    margin-top: var(--chakra-space-1);
    font-size: var(--chakra-fontSizes-sm);
}

.details > h3 {
    font-size: medium;
    font-weight: bold;
    padding-top: 35px;
    padding-bottom: 5px;
}