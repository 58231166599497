.users {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0.8;
  padding-bottom: 50px;
}

.users__filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 1200px;
  margin-top: 50px;
  margin-bottom: 20px;
}

.select_filter {
  width: 150px !important;
}

.MuiTablePagination-root {
  display: table-footer-group;
}

/* Table user details */
.users__userDetails {
  margin-left: 10px;
}

.users__userDetails > p:nth-child(1) {
  text-transform: capitalize;
}

.users__userDetails > p:nth-child(2) {
  color: grey;
}

.users__planDetails > p:nth-child(2) {
  color: grey;
}

/* To remove extra space from front of the Footer > pagination data */
.MuiTablePagination-spacer {
  flex: 0 !important;
}

.MuiTypography-root {
  font-size: 13px !important;
}

.MuiInputBase-root {
  font-size: 13px !important;
}

.topbar__input {
  display: flex;
  align-items: center;
  /* width: 170px; */
  /* padding: 5px; */
  /* background-color: #ffffff; */
}

.users__filter > form {
  display: flex;
  align-items: center;
}

.users__filter > form > div {
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 8px 15px;
  border-radius: 6px;
  border: none;
  outline-width: 0;
  width: 180px;
  margin-right: 5px;
  font-size: 13px;
}

.users__filter > form > div > input {
  background: transparent;
  outline: none;
  width: 135px;
}

.date__input {
  color: rgb(158, 158, 158);
  background: #ffffff;
  padding: 8px 12px;
  border-radius: 6px;
  width: 200px;
  font-size: 13px;
  outline: none;
  cursor: pointer;
}

.custom_calender {
  font-size: 9px !important;
}

@media screen and (max-width: 620px) {
  .users__filter {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    justify-content: space-evenly;
  }
  .date__input {
    width: 195px;
  }
}

@media screen and (max-width: 800px) {
  .users__filter {
    /* justify-content: flex-start; */
    width: fit-content;
  }
  .users__filter > form {
    padding-right: 15px;
  }
}
