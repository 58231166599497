/* .export {
    margin-left: auto;
} */

.export > button {
    font-size: 13px;
    font-weight: bold;
    padding: 7px 14px;
    margin-left: 15px;
    border-radius: 5px;
    border: 1px solid #cac9c9;
    cursor: pointer;
}

.export > button:hover {
    background-color: #def0e3;
}